<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card mt-half border">
          <div class="psh-header p-1 mb-0 d-flex align-items-center border-bottom">
            <div class="mr-1 border-0">
              <p-icon name="bi-signpost-2" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Swagger
              </h4>
              <p class="card-text mb-0 text-muted">
                Press Get, Post, Put or Delete buttons to make API request.
              </p>
            </div>
          </div>
          <div class="d-flex p-half mt-1 mx-1">
            <dx-util-text-box v-model="api" label="API Endpoint" placeholder="/api" class="col-12" label-mode="static" />
          </div>
          <div class="d-flex p-half mx-1">
            <dx-util-text-area v-model="data" label="Data" placeholder="{data: 'data'}" class="col-12" :max-height="300" :auto-resize-enabled="true" />
          </div>
          <div class="d-flex p-half mt-2 mx-1">
            <dx-util-button-group
              :items="statusOptions" key-expr="value"
              selection-mode="none" styling-mode="contained"
              :on-item-click="submitForm"
              :active-state-enabled="true"
            />
            <div class="flex-grow-1"></div>
            <dx-util-button text="Clear Form" type="default" class="mr-1 text-white" :on-click="resetForm" />
            <dx-util-button text="Copy Response" type="success" :on-click="copyResponse" />
          </div>
          <div class="divider my-1">
            <div class="divider-text text-warning">
              Response
            </div>
          </div>
          <div class="d-flex pt-half pb-1 mx-1">
            <dx-util-text-area v-model="result" label="Response" class="col-12" :min-height="300" :max-height="600" :auto-resize-enabled="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      statusFilter: null,
      statusOptions: [
        { text: 'GET', value: 'Get' },
        { text: 'POST', value: 'Post' },
        { text: 'PUT', value: 'Put' },
        { text: 'DELETE', value: 'Delete' },
      ],
      api: '',
      result: '',
    }
  },
  methods: {
    resetForm() {
      this.result = null
      this.api = null
      this.data = null
    },
    submitForm(e) {
      const method = e.itemData.value
      if (method === 'Post') {
        this.$http.post(this.api, JSON.parse(this.data)).then(response => {
          this.result = JSON.stringify(response.data.body)
        })
      }
      if (method === 'Get') {
        this.$http.get(this.api).then(response => {
          this.result = JSON.stringify(response.data.body)
        })
      }
      if (method === 'Put') {
        this.$http
          .put(this.api, JSON.stringify(JSON.parse(this.data)))
          .then(response => {
            this.result = JSON.stringify(response.data.body)
          })
      }
      if (method === 'Delete') {
        this.$http.delete(this.api).then(response => {
          this.result = JSON.stringify(response.data.body)
        })
      }
    },
    async copyResponse() {
      await navigator.clipboard.writeText(this.result)
    },
  },
}
</script>

<style lang="scss">
</style>
